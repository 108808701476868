body {
  font-family: 'Monda';
  overflow: hidden;
}

.App {
  text-align: center;
}

.logo {
  height: 25vmin;
  pointer-events: none;
  padding-bottom: 150px;
}

.App-header {
  background-color: #242528;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.subtext {
  margin: 0;
}

.contact-button {
  background-color: #eb5940;
  color: white;
  padding: 15px 25px;
  text-decoration: none;
  border-radius: 15px;
  margin-top: 100px;
}

.skills {
  display: flex;
  flex-direction: row;
}

.subtext {
  margin-left: 50px;
  margin-right: 50px;
}

@media only screen and (max-width: 670px) {
  .skills {
    flex-direction: column;
  }

  .subtext {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.terms {
  position: absolute;
  color: white;
  bottom: 0;
  right: 0;
  font-size: small;
}
